import React from "react";
import { isMobile } from "react-device-detect"
import { useSelector, useDispatch } from "react-redux";
import { useCookies } from 'react-cookie';
import SEO from "../components/seo/seo";
import Layout from "../components/layout/layout";
import AppBar from "../components/appBar/appBar";
import MainSection from "../components/mainSection/mainSection";
import PageSection from "../components/pageSection/pageSection";
import Card from "../components/card/card";
import SecondarySection from "../components/secondarySection/secondarySection";
// import LaunchForm from "../components/launchForm/launchForm";
import OptOut from "../components/optOut/optOut";
import IFrame from "../components/iFrame/iFrame";
import Modal from "../components/modal/modal";

import * as ACTIONS from "../state/actions"

const tcPdf = <IFrame file="../../TC-Usuario.pdf" title="Terms of use"/>;
const pcPdf = <IFrame file="../../PC.pdf" title="Privacy policy"/>;
const footerDesc = "EVVA is a digital platform through which you can manage business financial services. All financial services offered on the platform are carried out by one or more financial institutions, which are regulated and supervised by the CNBV and CONDUSEF. EVVA Plataforma, S.A.P.I. de C.V. It is only the technological intermediary between the user of the platform and said financial institutions, and therefore does not carry out financial activities or activities that are regulated or supervised by any authority.";
const contentMaxWidth = "780px";

const Connect = () => {
    const appState = useSelector(state => state.appReducer);
    const dispatch = useDispatch();

    const [cookies, setCookie] = useCookies(['consent']);

    const launchFormHandlerConnect = () => {
        window.location.href = "#evva";

        /*window.scroll({ top: 0, left: 0, behavior: "smooth" });

        const modal = {
            display: true,
            content: <LaunchForm lang={appState.lang} title="Thanks for your interest! We are about to launch 🚀" subtitle="Request your invitation and you will be one of the first to know when we are ready." amplitude={appState.amplitude} amplitudeClickEvent="PreSignUpCreated"/>
        };

        dispatch(ACTIONS.showModal(modal))*/
    };

    const launchFormHandler = () => {
        window.open("https://evvafinanzas.typeform.com/to/VFsgS0", "blank");
    };

    const contactUsFormHandler = () => {
        window.location.href="mailto:partners@evvafinanzas.com";
    };

    const termsUseHandler = () => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
        dispatch(ACTIONS.showModal({display: true, content: tcPdf }))
    };

    const privacyPolicyHandler = () => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
        dispatch(ACTIONS.showModal({display: true, content: pcPdf }))
    };

    const cookieHandler = () => {
        setCookie("consent", "dismiss", { path: '/' });
    };

    const closeModal = () => dispatch(ACTIONS.hideModal());

    return (
        <Layout lang={appState.lang} footerDesc={footerDesc} privacyPolicyAction={privacyPolicyHandler} termsUseHandlerAction={termsUseHandler}>
             <SEO lang={appState.lang} title="Connect financial services and simplify your business" description="With EVVA you can connect financial services that simplifies your financial life, so you can focus on your most important work."/>
            { appState.modal.display && <Modal content={appState.modal.content} closeModal={closeModal}/> }
            { !cookies.consent &&
                <OptOut text="This websites uses cookies to ensure that you get the best experience on our website." privacyPolicyAction={privacyPolicyHandler} privacyPolicyLabel="See Privacy Policy" cookieAction={cookieHandler}/>
            }
            <MainSection 
                title="Connect financial services and simplify your business"
                description="<h3>With EVVA you can connect financial services that simplifies your financial life, so you can focus on your most important work.</h3>"
                textColor="primary"
                position="left"
                image={ isMobile ? "hero-evva-connect-mobile.png" : "hero-evva-connect-desktop.png" }
                actionLabel="Connect now"
                action={launchFormHandlerConnect} 
                amplitude={appState.amplitude}
                amplitudeClickEvent="ClickPreSignUp"
            >
                <AppBar pathname={appState.path}/>
            </MainSection>
            <PageSection
                title="It’s not easy to be an entrepreneur"
                maxColumns="4"
            >
                <Card
                    imageHeight="160px"
                    imageWidth="240px"
                    image="beaurocracy-icon.png"
                    description="<p>Bureaucracy, paperwork, long calls, visits to bank, and weeks of effort  to get a business bank account</p>"
                    textAlign="center"
                    descriptionColor="#333A42"
                />
                <Card
                    imageHeight="160px"
                    imageWidth="240px"
                    image="insane-interest-rates-icon.png"
                    description="<p>Getting financing is time consuming,  small letters all around, insane interests rates  and lack of transparency</p>"
                    textAlign="center"
                    descriptionColor="#333A42"
                />
                <Card
                    imageHeight="160px"
                    imageWidth="240px"
                    image="sat-tax-information-icon.png"
                    description="<p>SAT Tax Information? No thank you. It's difficult to handle, to understand, to process and manage</p>"
                    textAlign="center"
                    descriptionColor="#333A42"
                />
                <Card
                    imageHeight="160px"
                    imageWidth="240px"
                    image="insecure-transaction-icon.png"
                    description="<p>Insecure transactions and fraud all around when trying to buy or sell. Scammers are just around the corners </p>"
                    textAlign="center"
                    descriptionColor="#333A42"
                />
            </PageSection>
            <div id="evva">
                <PageSection
                    title="Introducing EVVA Connect"
                    description="Entrepreneur,  we got your back. With our digital platform and Partners, EVVA enable you to connect to the best financial experiences, simplifying the daily financial needs of your business"
                    maxWidth={contentMaxWidth}
                >
                    <div style={{ textAlign: "center", margin: "0px 0px 22px 0px", fontSize: "21px" }}>
                        Some of our favourite financial experience you can connect
                    </div>
                    <Card
                        title="Kuspit High Yield Business account"
                        description="<p>With EVVA and Kuspit, you can get a hight yield digital business bank account that makes your money grow daily. No more paperwork and bureaucracy.</p>"
                        descriptionColor="#363636"
                        image="high-yield-account.svg"
                        imageWidth="180px"
                        imageHeight="200px"
                        imageBgColor="_evva_business"
                        link="#"
                        linkLabel="Connect now"
                        amplitude={appState.amplitude}
                        amplitudeLinkEvent="ClickPreSignUp"
                        hasBgImage
                        imageShadow
                    />
                    <Card
                        title="SAT insights"
                        description="<p>Connect your SAT account, and EVVA give you a simple business cash flow in real time, no more darkness and uncertainty.</p>"
                        descriptionColor="#363636"
                        image="logo-sat-white.svg"
                        imageWidth="180px"
                        imageHeight="200px"
                        imageBgColor="_sat_info_en"
                        link="/en/taxes-sat/"
                        linkLabel="Connect now"
                        amplitude={appState.amplitude}
                        amplitudeLinkEvent="ClickLearnEVVASAT"
                        hasBgImage
                        imageShadow
                    />
                    <Card
                        subtitle="Secure Escrow Transactions"
                        description="<p>Are you selling or buying? Avoid fraud and Secure your money with Fido’s Digital Escrow powered by EVVA</p>"
                        descriptionColor="#363636"
                        image="logo-fido-white.svg"
                        imageWidth="180px"
                        imageHeight="200px"
                        imageBgColor="_fido_connect_info_en"
                        link="https://www.escrow.fido.mx/"
                        linkLabel="Learn more"
                        amplitude={appState.amplitude}
                        amplitudeLinkEvent="ClickLearnEVVAFido"
                        hasBgImage
                        imageShadow
                    />
                    <Card
                        subtitle="Open Banking"
                        description="<p>Connect your favourite bank accounts and have a complete overview of your finances</p>"
                        descriptionColor="#363636"
                        image="multi-account.svg"
                        imageWidth="180px"
                        imageHeight="200px"
                        imageBgColor="_multi_account_info_en"
                        //link="#"
                        //linkLabel="Learn more"
                        amplitude={appState.amplitude}
                        amplitudeLinkEvent="ClickLearnEVVAFido"
                        hasBgImage
                        imageShadow
                    />
                </PageSection>
            </div>
            <PageSection maxColumns={2} maxWidth={contentMaxWidth}>
                <Card
                    subtitle="Business Loans"
                    description="<p>Expand, boost cash flow or fund your next step with finance that arrives in days with EVVA Uniclick financing</p>"
                    descriptionColor="#363636"
                    image="logo-uniclick-white.png"
                    imageWidth="240px"
                    imageHeight="200px"
                    imageBgColor="_uniclick"
                    link="#"
                    linkLabel="Learn more"
                    amplitude={appState.amplitude}
                    amplitudeLinkEvent="ClickLearnEVVAUniclick"
                    imageShadow
                />
                <Card
                    subtitle="Buy now pay later"
                    description="<p>Increase your sales and provide the best shopping experiences with Slightpay buy now pay later</p>"
                    descriptionColor="#363636"
                    image="slightpay.svg"
                    imageWidth="240px"
                    imageHeight="200px"
                    imageBgColor="_slightpay"
                    link="#"
                    linkLabel="Learn more"
                    amplitude={appState.amplitude}
                    amplitudeLinkEvent="ClickLearnEVVAUniclick"
                    imageShadow
                />
            </PageSection>
            <PageSection maxWidth="980px" bgColor="#F9F9F9">
                <Card
                    title="Join our mission"
                    description="<p>Are you bank, fintech or startup and share our mission?</p>"
                    descriptionColor="#363636"
                    actionLabel="Get in touch"
                    action={contactUsFormHandler}
                    image="legal-guide.png"
                    imageWidth="420px"
                    imageHeight="340px"
                    marginTopContent="60px"
                    horizontal
                    padding
                />
            </PageSection>
            <SecondarySection
                title="Have something on your mind?"
                shortDescription="Get in touch and we’ll help you explore how you can build a custom financial product that’s right for your customers."
                position="center"
                image="evva-lego-bricks.png"
                actionLabel="Get in touch"
                action={launchFormHandler}
                amplitude={appState.amplitude}
                amplitudeClickEvent="ClickPreSignUp"
            />
            
        </Layout>
    )
};

export default Connect;
